export default {
    "title": "🇺🇸 English",

    "首页": "Home",
    "关于": "About",
    "会员": "Membership",
    "推荐计划": "Referral Program",
    "个人": "Profile",
    "推荐": "Referral",
    "预订": "Reserve Now",
    "事件": "Event",
    "登出": "Logout",
    "登录": "Sign In",
    "注册": "Register",
    "我们的应用程序现在可用": "Our App Available Now",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "For greater experience, we have just launced our mobile apps for seamless and faster booking.",
    "iOS版下载（即将推出）": "Download for iOS (Coming Soon)",
    "Android版下载（即将推出）": "Download for Android(Coming Soon)",
    "为什么选择我们": "Why Choose Us",
    "我们卓越的服务。": "Our outstanding services.",
    "收入最高的酒店": "Top Earnings Attractions",
    "我们的合作酒店收入最高。": "Our collabration Attractions with highest earnings.",
    "回报": "Returns",
    "最具特色的酒店": "Most Featured Attractions",
    "我们平台上最有特色的酒店。": "Most featured hotel in our platform.",
    "热门国家和目的地": "Top Countries & Destination",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "Explore some of the best tips from around the city from our partners and friends.",
    "酒店": "Attractions",
    "预订酒店并开始赚取": "Explore the world with us！",
    "让我们开始与TitanTraveler一起探索世界": "Get ready to explore the world of Attractions with us!",
    "客户评价": "Testimonials",
    "查看我们用户的真实评价。": "Check our authentic testimonials from our users.",
    "银行卡列表": "Bank Card List",
    "银行列表": "Bank List",
    "持卡人姓名": "Cardholder name",
    "银行卡号": "Bank Accound",
    "支付密码": "Pay Password",
    "提交": "Submit",
    "Pix列表": "Pix List",
    "Pix类型": "Pix Type",
    "Pix账号": "Pix Accound",
    "账号列表": "Accound List",
    "网络": "Network",
    "地址": "Address",
    "备注": "Note",
    "充值金额": "Recharge Amount",
    "支付类型": "Pay Type",
    "支付银行": "Pay Bank",
    "USDT地址": "USDT Address",
    "二维码": "QR Code",
    "充值渠道": "Recharge Channel",
    "充值凭证": "Recharge Voucher",
    "立即登录": "Login Now",
    "用户名": "Username",
    "密码": "Password",
    "邀请码": "Invite Code",
    "确认密码": "Confirm Password",
    "手机": "Mobile",
    "注册2": "Sign Up",
    "金额": "Amount",
    "新增": "New",
    "我们的精彩": "Our Awesome",
    "故事": "Story",
    "奖项": "Awards",
    "获得": "Gained",
    "钱包余额": "Wallet Balance",
    "今日奖励": "Today's Rewards",
    "总行程": "Total Journey",
    "访问行程": "Visited Journeys",
    "酒店历史": "Reservation History",
    "单价": "Price",
    "数量": "Number",
    "总额": "Total",
    "奖励": "Rewards",
    "上一页": "Prev",
    "下一页": "Next",
    "每套预订量": "Bookings Per Set",
    "预订奖励": "Booking Reward",
    "每日预订": "Booking Daily",
    "手机号码": "Phone Number",
    "修改密码": "Change Password",
    "登录密码": "Login Password",
    "当前密码": "Current Password",
    "新密码": "New Password",
    "确认新密码": "Confirm New Password",
    "保存修改": "Save Changes",
    "充值": "Top Up",
    "我们的": "Our",
    "复制": "Copy",
    "分享链接": "Share Link",
    "搜索酒店": "Reserve Journey",
    "实际等级": "Actual Level",
    "升级": "Upgrade",
    "已完成预订": "Bookings Completed",
    "登录时间": "Login Time",
    "信誉分": "Credibility",
    "控制菜单": "Dashboard Menu",
    "提现": "Withdraw",
    "提现账号": "Withdraw Account",
    "你确定要删除此账号吗": "Are you sure delete this account",
    "你确定要删除此地址吗": "Are you sure delete this address",
    "提示": "Tips",
    "是": "Yes",
    "否": "No",
    "全部": "All",
    "待处理": "Pending",
    "冻结中": "Freezing",
    "已完成": "Completed",
    "订阅": "Subscribe",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Want to be notified when we launch a new template or an udpate. Just sign up and we'll send you a notification by email.",
    "我们的伙伴": "Our partners",
    "关于我们": "ABOUT US",
    "实时聊天支持": "Live Chat Support",
    "在线聊天": "Live Chat",
    "创建时间": "Created Time",
    "你确定要登出当前账号吗？": "Do you want to log out?",
    "请输入你的邮箱": "Enter Your Email",
    "好评": "Very Good",
    "条评论": "Reviews",
    "平均每晚": "Avg/Night",
    "旅行旅程": "Travel Journeys",
    "正在寻找酒店": "Looking for a journey",
    "开始预订": "Start Reservation",
    "旅程价格": "Journey Price",
    "佣金": "Commissions",
    "底部-about": "We are a Spain-based digital marketing agency that helps businesses succeed in the digital world through innovative and data-driven marketing strategies, personalized services, and a growth mindset that keeps up with industry trends.Whether you need a search engine optimization company, website designer, pay-per-click management, brand strategy, content development or other digital marketing services, Iberostar Group has the expertise and experience to help you achieve your goals in a results-driven world.",
    "立即预订": "Booking now",
    "搜索": "Search",
    "快乐的客户": "Happy Client",
    "快乐的酒店老板": "Happy Hotel Owner",
    "评论1": "Architecture on buildings.",
    "评论2": "This is a great place for a walk with narrow streets and some lovely architecture on the buildings.",
    "评论3": "Beautiful square between the beach and Central Avenue.",
    "评论4": "Very nice buildings with an interesting history.",
    "评论5": "Lots of restaurants to choose from and a very relaxed atmosphere.",
    "评论6": "Beautiful land with lovely orange groves and animals.",
    "评论7": "Great place for a weekend getaway.",
    "评论8": "Interesting place ......",
    "评论9": "A great place to sit back and watch the world go by.",
    "评论10": "High end beach club which is a lovely tribute to those who died in the war.",
    "评论11": "There is so much to see and it's a great place to vacation and relax!",
    "评论12": "First time here and it was very pleasant, will be back next time.",
    "评论13": "It was a different experience and felt great, the locals were very friendly and polite!",
    "推荐计划1": "Platform users can invite others to become platform agents through referral codes and become your downline.",
    "推荐计划2": "As the upline, you can extract a certain percentage of your downline’s profit, and the profit obtained by the upline will be directly returned to the upline’s platform account, or to the team report.",
    "推荐计划3": "Note: All agents / downlines of the platform will receive a certain percentage of the platform’s profits and rewards accordingly, developing a team does not affect the profits and rewards of any agent or downlines.",
    "评分": "Rating",
    "评论": "Comment",
    "酒店很棒": "The hotel is great",
    "终于体验了，酒店非常好": "Finally experienced it, the hotel is very good",
    "我对酒店非常满意并推荐预订": "I am very satisfied with the hotel and recommend booking",
    "我很高兴很满意，价格实惠": "I am very happy and satisfied, the price is affordable",
    "服务非常好，我非常喜欢": "The service is very good, I like it very much",
    "体验很不错，朋友已经第二次预订了": "The experience was very good, my friend has already booked it for the second time",
    "酒店环境优美": "Beautiful hotel environment",
    "酒店卫生干净": "Hotel is hygienic and clean",
    "工作人员态度非常好": "Staff attitude is very good",
    "酒店服务很棒": "Hotel service is great",
    "你还没有设置支付密码，请前往设置": "You have not set a payment password, please go to settings",
    "订单": "Orders",
    "复制成功": "Copy successful",
    "团队与条件": "TERMS and conditions",
    "站内信": "Site messages",
    "请联系客服升级会员": "Please contact customer service for upgrading membership",
    "我已阅读并同意": "I have read and agreed to the",
    "注册协议": "Registration Agreement",
    "忘记密码": "Forgot password",
    "账户明细": "Account details",
    "时间": "Time",
    "类型": "Type",
    "代理支持": "Agent Support",
    "冻结金额": "Frozen amount",
}